.scoreHeading {
    margin-bottom: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  }

  .para {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: bold;
  }

  .scorePara {
    text-align: center;
    font-size: 0.5rem;
  }

  .scoreFooter {
    margin-top: 1.5rem;
  }
  .heading{
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .container {
    background-color: var(--color-white);
    border-radius: 1.5rem;
    box-shadow: 0 0 1.9rem rgba(0, 0, 0, 0.4);
    width: 50rem;
    height: 70%;
    align-self: center;
    padding: 1.8rem;
  }

  .buttonContainer{
    text-align: center;
  }
  
  .contents {
    display: flex;
    flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80%;
    }
   
    .heading {
      padding: 0 0.6rem;
      color: var(--color-gray-1);
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.3;
      text-align: center;
    }

    @media screen and (min-width:  1537px) {
      .scoreHeading {
        margin-bottom: 1rem;
        padding: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        margin-top: 5rem;
      }
    
      .para {
        font-size: 1rem;
        text-align: center;
      }

      .heading{
        font-size: 1.5rem;
        margin-bottom: 4rem;
      }
    
    }

    @media screen and (min-width: 1301px) and (max-width: 1536px) {
      .scoreHeading {
        margin-bottom: 1rem;
        padding: 1rem;
        font-size: 1.3rem;
        font-weight: 600;
        text-align: center;
        margin-top: 4rem;
      }
    
      .para {
        font-size: 1rem;
        text-align: center;
      }
    
      .heading{
        font-size: 1.5rem;
        margin-bottom: 4rem;
      }

    
    }
   
    @media screen and (min-width: 1101px) and (max-width: 1300px) {
      .scoreHeading {
        margin-bottom: 1rem;
        padding: 1rem;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
        margin-top: 3rem;
      }
    
      .para {
        margin-bottom: 4rem;
        font-size: 0.9rem;
        text-align: center;
      }

      .heading{
        font-size: 1.5rem;
        margin-bottom: 4rem;
      }
     
    }
    @media screen and (min-width: 481px) and (max-width: 1100px) {
      .container{
        width: 80%;
      }

      .para {
        font-size: 0.7rem;
        text-align: center;
      }
    
      .heading{
        font-size: 1.2rem;
        margin-bottom: 3rem;
      }
      
    }
  
  
    @media screen and (max-width: 480px) {
      .container{
        width: 100%;
      }
      .heading{
        font-size: 1rem;
        margin-bottom: 2rem;
      }
    
      .para {
        font-size: 0.7rem;
        text-align: center;
      }
    }
  
