.fieldError {
    color: hsl(0, 72.2%, 50.6%);
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: right;
}

@media screen and (min-width: 751px) and (max-width: 1300px) {
    .fieldError {
        color: hsl(0, 72.2%, 50.6%);
        font-size: 0.7rem;
        line-height: 1rem;
    }
}

@media screen and (max-width: 750px) {
    .fieldError {
        color: hsl(0, 72.2%, 50.6%);
        font-size: 0.6rem;
        line-height: 1rem;
    }
}
