.input {
    display: flex;
    flex-flow: column nowrap;
    row-gap:  0.37rem;
    position: relative;
    
    &.email {
      .errorContainer {
        position: initial;
    
      }
    }
    .errorContainer {
      position: absolute;
      bottom: -20px;
      right: 0px;
    }
    
  
    label {
      padding: 0 0.1rem;
      font-size: 1.2rem;
      line-height: 2;
      color: var(--color-gray-1);
      font-weight: bold;
    }
     
    .inputFieldError {
      border-color:#FF1818 !important
    }

    input {
      // it has been used to fix date ui issue in ipad
      appearance: none;
      padding: 0.8rem 0.8rem;
      transition-property: border-color, box-shadow;
      transition-duration: 0.3s;
      border: 1px solid #D9D9D9;
      border-radius: 0.4rem;
      background-color: #ffffff;
      line-height: 0.65rem;
      &:hover,
      &:focus {
        border-color: var(--focus-border-color);
        box-shadow: 0 0 0.05rem 0.1rem var(--focus-shadow-color);
      }
    }
  
    input::placeholder {
      font-size: 1rem !important;
      color: var(--color-gray-3) !important;
    }
  }
  
  /* stylelint-disable-next-line no-descending-specificity */
  .invalid input {
    border-color: transparent;
  }
  
  @media screen and (min-width: 1537px) {
    .input {
      row-gap:  0.37rem;
  
      label {
        font-size: 1rem;
      }
  
      input {
        padding: 0.8rem 0.8rem;
        border-radius: 0.4rem;
        font-size: 0.9rem !important;
        border: 1px solid #D9D9D9;

      }
  
      input::placeholder {
        font-size: 0.9rem !important;
        // color: var(--color-gray-3) !important;
      }
    }
  }
  
  @media screen and (min-width: 1301px) and (max-width: 1536px) {
    .input {
      label {
        font-size: 0.8rem;
      }
  
      input {
        padding: 0.8rem 0.8rem;
        border-radius: 0.3rem;
        font-size: 0.9rem !important;

      }
  
      input::placeholder {
        font-size: 0.9rem !important;
        // color: var(--color-gray-3) !important;
      }
    }
  }
  
  @media screen and (min-width: 1101px) and (max-width: 1300px) {
    .input {
      label {
        font-size: 0.7rem;
      }
  
      input {
        padding: 0.5rem 0.8rem;
        border-radius: 0.25rem;
        font-size: 0.8rem !important;

      }
  
      input::placeholder {
        font-size: 0.8rem !important;
        // color: var(--color-gray-3) !important;
      }
    }
  }
  
  @media screen and (min-width: 751px) and (max-width: 1100px) {
    .input {
      label {
        font-size: 0.65rem;
      }
  
      input {
        padding: 0.5rem 0.8rem;
        border-radius: 0.3rem;
        font-size: 0.75rem !important;
      }
  
      input::placeholder {
        font-size: 0.75rem !important;
        // color: var(--color-gray-3) !important;
      }
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 750px) {
    .input {
      label {
        font-size: 0.75rem;
      }
      input {
        row-gap:  0.37rem;
        @media screen and (max-height: 880px) {
          .header{
            img{
              margin-bottom: 0;
            }
          }
        }
        padding: 0.5rem 0.8rem;
      }
      
    }
  }
  
  @media screen and (max-width: 480px) {
    .input {
      label {
        font-size: 0.75rem;
      }
      input {
        row-gap:  0.37rem;
        @media screen and (max-height: 880px) {
          .header{
            img{
              margin-bottom: 0;
            }
          }
        }
        padding: 0.5rem 0.8rem;
      }
      
    }
  }