h1 {
    margin-bottom: 1.5rem;
    padding: 1rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  }


  h2 {
    padding: 1rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  }

  .section {
    display: grid;
    row-gap: 1rem;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .disabled {
    pointer-events: none;
    opacity: .5;
  }

  .buttonContainer{
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    button {
      width: 100%;
      margin: 0px;
    }
  }

.backArrow {
  position: absolute;
  left: -40px;
  top: -20px;
  height: 25px !important;
  width: auto !important;
  cursor: pointer;
}

  .container {
    background-color: #FFFAEE;
    border-radius: 20px;
    border: 1px solid #B3B3B3;
    max-width: 930px;
    width: 100%;
    padding: 40px 70px;
    overflow: hidden;
    z-index: 9;
    position: relative;
    display: block;
  }
  
  .contents {
      position: relative;
    }
   
    .heading {
      padding: 0 0.6rem;
      color: var(--color-gray-1);
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.3;
      text-align: center;
      margin-bottom: .5rem !important;
    }
    .para {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: var(--color-gray-1);
      margin-bottom: 1.6rem !important;

    }
    .empty{
      width: 10rem;
    }
    @media screen and (min-width: 1357px) {
      .buttonContainer{
        button {
          padding: 13px;
        }
      }
      .heading{
        font-size: 2rem;
      }

      .para {
        font-size: 18px;
      }
    }

    @media screen and (min-width: 1101px) and (max-width: 1356px) {
      .buttonContainer{
        button {
          padding: 13px;
        }
      }
      .heading{
        font-size: 1.7rem;
      }
      .para {
        font-size: 16px;
      }
    }

    @media screen and (min-width: 751px) and (max-width: 1100px) {
      .buttonContainer{
        button {
          padding: 13px;
        }
      }
      .heading{
        font-size: 1.6rem;
        margin-bottom: .3rem !important;

      }
      .para {
        font-size: 16px;
      }
    }

    @media screen and (min-width: 481px) and (max-width: 750px) {
      .backArrow {
        left: -26px !important;
        top: -4px !important;
      }
      .container{
        width: 80%;
        padding: 2rem;
      }

      .heading{
        font-size: 1.5rem;
        margin-bottom: .3rem !important;

      }
      .para {
        font-size: 12px;
      }
    }
    @media screen and (max-width: 650px) {
      .para {
        line-height: 17px !important;

      }
    }
  
    @media screen and (max-width: 480px) {

      .backArrow {
        height: 22px !important;
        left: -25px !important;
        top: -4px !important;
      }

      .container{
        padding: 2rem;
      }

      .heading{
        font-size: 1rem;
      }
      .para {
        font-size: 12px;
      }
    }
  
    @media (min-width: 660px) {
      .section {
        grid-template-columns: 1fr 1fr;
        column-gap: 24px;
        row-gap: 20px;
      }
    }

    @media screen and (max-width: 480px) and (max-height:680px) {
        .container {
          max-height: 550px !important;
          overflow: scroll !important;
          height: 100%;
        }
      
    
    }