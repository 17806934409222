.select {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 0.37rem;
  
    label {
      padding: 0 0.1rem;
      font-size: 1rem;
      line-height: 2;
      color: black;
      font-weight: bold;
    }
  
    .inputFieldError {
      border-color:#FF1818 !important
    }

    select {
      width: 100%;
      transition-property: border-color, box-shadow, color;
      transition-duration: 0.3s;
      border: 0.05rem solid transparent;
      background-color: var(--form-background-color);
      border-radius: 0.4rem;
      font-weight: 500;
      line-height: 0.65rem;
      text-overflow: ellipsis;
      cursor: pointer;
      border: 0.05rem solid #D9D9D9;
      padding: 0.8rem 0.8rem;

      &:hover,
      &:focus {
        border-color: var(--focus-border-color);
        box-shadow: 0 0 0.05rem 0.1rem var(--focus-shadow-color);
      }
    }
  
    svg {
      position: relative;
      right: 0.75rem;
      justify-self: end;
      pointer-events: none;
    }
  
    .info {
      margin: auto 0.5rem;
    }
  }
  
  .selectOption{
    background-color: var(--form-background-color);
  }
  .control {
    display: grid;
    align-items: center;
  
    > * {
      grid-column: 1 / 1;
      grid-row: 1;
    }
  }
  
  /* stylelint-disable-next-line no-descending-specificity */
  .empty select {
    // color: var(--color-gray-4);
  }
  
  /* stylelint-disable-next-line no-descending-specificity */
  .invalid select {
    border-color: var(--color-red);
  }
  
  // @media screen and (min-width: 950px) {
  //   .select {
  //     row-gap: 0.95rem;
  
  //     label {
  //       font-size: 0.9rem;
  //     }
  
  //     select {
  //       padding: 0.95rem 2.6rem 0.95rem 1.2rem;
  //       border-radius: 0.65rem;
  //       font-size: 16px;
  //     }
  //   }
  // }
  
  @media screen and (min-width: 1537px) {
    .select {
  
      label {
        font-size: 1rem;
      }
  
      select {
        padding: 0.8rem 0.8rem;
        border-radius: 0.4rem;
        font-size: 1rem;
      }
    }
  }
  
  @media screen and (min-width: 1301px) and (max-width: 1536px) {
    .select {
  
      label {
        font-size: 0.8rem;
      }
  
      select {
        padding: 0.8rem 0.8rem;
        border-radius: 0.3rem;
        font-size: 0.9rem;
      }
    }
  }
  
  @media screen and (min-width: 1101px) and (max-width: 1300px){
    .select {
  
      label {
        font-size: 0.7rem;
      }
  
      select {
        padding: 0.5rem 0.8rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
      }
    }
  }
  
  
  @media screen and (min-width: 951px) and (max-width: 1100px) {
    .select {
  
      label {
        font-size: 0.65rem;
      }
  
      select {
        padding: 0.5rem 0.8rem;
        border-radius: 0.3rem;
        font-size: 0.75rem;
      }
    }
  }
  
  @media screen and (min-width: 751px) and (max-width: 950px) {
    .select {
  
      label {
        font-size: 0.65rem;
      }
  
      select {
        padding: 0.5rem 0.8rem;
        border-radius: 0.3rem;
        font-size: 0.75rem;
      }
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 750px) {
    .select {
  
      label {
        font-size: 0.75rem;
      }
  
      select {
        padding: 0.5rem 0.8rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
      }
    }
  }
  
  @media screen and (max-width: 480px) {
    .select {
  
      label {
        font-size: 0.75rem;
      }
  
      select {
        padding: 0.5rem 0.8rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
      }
    }
  }