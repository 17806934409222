main {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-image: url('../../assets/images/new bg and logos/newBg_50.webp');
  background-size: cover;
  background-position-y: 100%;
}

.container {
  background-color: var(--color-white);
  border-radius: 1.5rem;
  box-shadow: 0 0 1.9rem rgba(0, 0, 0, 0.4);
  width: 55rem;
  align-self: center;
  padding: 1.8rem;
}

.screeningHeader {
  font-weight: 600;
  text-align: center;
}

@media (min-width: 660px) {
  .container {
    border-radius: 16px;
    padding: 5rem;
  }
}
