.input {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0.37rem;
  position: relative;

  .errorContainer {
    position: absolute;
    bottom: -20px;
    right: 0px;
  }

  label {
    padding: 0 0.1rem;
    font-size: 1.2rem;
    line-height: 2;
    color: var(--color-gray-1);
    font-weight: bold;
  }

  :global(.ant-picker){
    border: 0rem;

    &:hover,
    &:focus {
      border-color: var(--focus-border-color) !important;
      box-shadow: 0 0 0.05rem 0.1rem var(--focus-shadow-color) !important;
    }

    &.inputFieldError:focus,
    &.inputFieldError:hover,
    &.inputFieldError {
      border-color:#FF1818 !important
    }

  }
  :global(.ant-picker){
    padding: 0.5rem 0.8rem;
    border: 1px solid #D9D9D9;

  }
  input {
    // it has been used to fix date ui issue in ipad
    appearance: none;
    transition-property: border-color, box-shadow !important;
    transition-duration: 0.3s;
    border: 0.05rem solid transparent !important;
    border-radius: 0.4rem;
    background-color: var(--form-background-color);
    line-height: 0.625rem;
    
  }

  input::placeholder {
    font-size: 0.9rem !important;
    color: var(--color-gray-3) !important;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.invalid input {
  border-color: var(--color-red);
}



@media screen and (min-width: 1537px) {
  .input {
    row-gap: 0.37rem;

    label {
      font-size: 1rem
    }

    input {
      border-radius: 0.4rem;
      font-size: 0.9rem !important;
    }



    input::placeholder {
      font-size: 0.9rem !important;
    }
  }
}

@media screen and (min-width: 1301px) and (max-width: 1536px) {
  .input {
    label {
      font-size: 0.8rem;
    }
    input {
      border-radius: 0.25rem;
      font-size: 0.87rem !important;
    }

    input::placeholder {
      font-size: 0.87rem !important;
    }

    :global(.ant-picker) {
      padding: 0.5rem 0.8rem;
    }
    
  }
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
  .input {
    label {
      font-size: 0.7rem;
    }

    input {
      border-radius: 0.25rem;
      font-size: 0.8rem !important;
    }

    input::placeholder {
      font-size: 0.8rem !important;
    }

    :global(.ant-picker) {
      padding: 0.3rem 0.8rem;
    }
  }
}

@media screen and (min-width: 751px) and (max-width: 1100px) {
  .input {
    label {
      font-size: 0.65rem;
    }
    input {
      padding: 0.2rem 0.8rem;
      border-radius: 0.25rem;
      font-size: 0.75rem !important;
    }

    input::placeholder {
      font-size: 0.75rem !important;
    }

    :global(.ant-picker) {
      padding: 0.3rem 0.8rem !important;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 750px) {
  .input {
    label {
      font-size: 0.75rem;
    }

    input {
      padding: 0.3rem 0.8rem;
      font-size: 0.75rem !important;
    }

    :global(.ant-picker) {
      padding: 0.3rem 0.8rem !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .input {
    label {
      font-size: 0.75rem;
    }

    input {
      padding: 0.3rem 0.8rem;
      font-size: 0.75rem !important;
    }

    :global(.ant-picker) {
      padding: 0.3rem 0.8rem !important;
    }
  }
}

.datePickerContainer {
  border: 0.05rem solid red; /* Default border color */
}