.topLogoContainer {
    display: flex;
    flex-direction: column;
    padding: 0rem;
}

.logo {
    width: 9rem
}

.quote {
    color: var(--color-emerald-forest);
    font-size: 1.2rem;
    font-weight: 600;
}

.yellowQuote {
    color: var(--color-orange);
}

@media screen and (min-width: 1537px) {
    .logo {
        width: 8rem;
    }
    
    .quote {
        color: var(--color-emerald-forest);
        font-size: 1.1rem;
        font-weight: 600;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1536px) {
    .logo {
        width: 5.1rem;
    }
    
    .quote {
        color: var(--color-emerald-forest);
        font-size: 1rem;
        font-weight: 600;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
    .logo {
        width: 5rem;
    }
    
    .quote {
        color: var(--color-emerald-forest);
        font-size: 1rem;
        font-weight: 600;
    }
}

@media screen and (min-width: 951px) and (max-width: 1100px) {
    .logo {
        width: 5rem;
    }
    
    .quote {
        color: var(--color-emerald-forest);
        font-size: 0.9rem;
        font-weight: 600;
    }
}

@media screen and (min-width: 751px) and (max-width: 950px) {
    .logo {
        width: 4.2rem;
    }
    
    .quote {
        color: var(--color-emerald-forest);
        font-size: 0.8rem;
        font-weight: 600;
    }
}

@media screen and (min-width: 481px) and (max-width: 750px) {
    .logo {
        width: 4rem;
    }
    
    .quote {
        color: var(--color-emerald-forest);
        font-size: 0.7rem;
        font-weight: 600;
    }
}

@media screen and (max-width: 480px) {

    .logo {
        width: 4rem;
    }
    
    .quote {
        color: var(--color-emerald-forest);
        font-size: 0.7rem;
        font-weight: 600;
    }
}