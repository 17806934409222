.wrapper{
    display: flex;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(246, 243, 243, 0.3);
    backdrop-filter: blur(10px);
  
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  
  /* code for custom loading icon */
  
  
  .messsage{
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
  }
  
  @media screen and (max-width: 480px) {
    .messsage{
      font-size: 0.6rem;
    }
  }
  
  @keyframes move {
    0%, 100% {
      transform: translateX(100%);
    }
    50% {
      transform: translateX(-100%);
    }
  }