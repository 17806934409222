.button {
  padding: 0.3rem 2rem;
  transition-property: border-color, box-shadow, color;
  transition-duration: 0.3s;
  border: 1px solid var(--color-orange);
  border-radius: 0.4rem;
 width: fit-content;
  background-color: var(--color-orange) !important;
  color: var(--color-black);
  font-weight: 600;
  order: 1;
  cursor: pointer;
  margin: 1rem 0.5rem;
  &:hover,
  &:focus {
    border-color: var(--focus-border-color);
    box-shadow: 0 0 0.05rem 0.1rem var(--focus-shadow-color);
  }
}

.backbutton {
  padding: 0.3rem 2rem;
  margin: 1rem 0.5rem;
  transition-property: border-color, box-shadow, color;
  transition-duration: 0.3s;
  order: 2;
  border: 0.05rem solid var(--color-gray-1);
  border-radius: 0.4rem;
  background-color: var(--color-white);
  color: var(--color-black);
  width: fit-content;
  font-weight: 600;

  cursor: pointer;

  &:hover,
  &:focus {
    border-color: var(--focus-border-color);
    background-color: var(--color-orange);
    box-shadow: 0 0 0.05rem 0.1rem var(--focus-shadow-color);
  }
}

.center {
  justify-self: center;
}

@media screen and (min-width: 651px) and (max-width : 950px) {
  .button, .backbutton {
    padding: 0.6rem 2rem;
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 951px) and (max-width: 1536px) {
  .button, .backbutton {
    padding: 0.5rem 2rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width : 650px) {
  .button, .backbutton {
    padding: 0.6rem 2rem;
    font-size: 0.6rem;
  }
}