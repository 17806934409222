.radioOption {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0;
    column-gap: 8px;

    label {
        padding: 0 0.1rem;
        font-size: 0.8rem;
        line-height: 1;
        
      }
}

@media screen and (max-width : 1024px) {
  .radioOption {

    label {
        font-size: 0.6rem;
        
      }
}
}


@media screen and (max-width : 650px) {
  .radioOption {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0;
    column-gap: 0.4rem;

    label {
        padding: 0 0.1rem;
        font-size: 0.6rem;
        line-height: 1;
        
      }
}

}