.topLogoContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    
  }

  .gridContainer {
    background: url('../../../assets/images/BackgroundNew.png');

    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
.jotForm {
    flex: 1; 
    width: 100%;
    height: 100%;
    z-index: 9;
    border: none;
}


.bottomRightImage {
  position: fixed;
  bottom: 30px;
  right: 30px;
}


.bottomLeftImage {
  position: fixed;
  bottom: 20px;
  left: 30px;
}

.hipaaImage{
  width: 90px !important;
  height: auto !important;
}

.logoImage {
  width: auto !important;
  height: 90px !important;
}
  
  @media screen and (min-width: 751px) and (max-width: 950px) {
    .gridContainer {
      grid-template-columns: 1fr;
      overflow-y: scroll;
    }
  }
  
  @media screen and (max-width: 750px) {
    .topLogoContainer {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    .gridContainer {
      grid-template-columns: 1fr;
      overflow-y: scroll;
    }
  
    .hipaaImage{
      width: 40px !important;
    }
    
    .logoImage {
      height: 50px !important;
    }
  }
  
  @media screen and (max-height: 860px) {
    .gridContainer {
      overflow-y: scroll;
    }
  }
  