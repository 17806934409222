.radio {
    display: flex;
    flex-flow: column nowrap;
    row-gap:  0.37rem;
  }
  
  .label {
    padding: 0 0.1rem;
    font-size: 1rem;
    line-height: 2;
    color: var(--color-gray-1);
    font-weight: bold;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
  }
  
  @media screen and (min-width: 1536px) {
    .radio {
      display: flex;
      flex-flow: column nowrap;
      row-gap:  0.37rem;
    }
  
    .label {
      font-size: 1rem;
    }
  
    .options {
      column-gap: 1rem;
    }
  }
  
  @media screen and (min-width: 1300px) and (max-width: 1536px) {
    .radio {
      display: flex;
      flex-flow: column nowrap;
      row-gap:  0.37rem;
    }
  
    .label {
      font-size: 0.8rem;
    }
  }
  
  @media screen and (min-width: 1100px) and (max-width: 1300px) {
    .radio {
      display: flex;
      flex-flow: column nowrap;
      row-gap:  0.37rem;
    }
  
    .label {
      font-size: 0.7rem
    }
  }
  
  @media screen and (min-width: 751px) and (max-width: 1100px) {
    .radio {
      display: flex;
      flex-flow: column nowrap;
      row-gap:  0.37rem;
    }
  
    .label {
      font-size: 0.65rem;
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 750px){
    .radio {
      display: flex;
      flex-flow: column nowrap;
      row-gap:  0.37rem;
    }
  
    .label {
      font-size: 0.75rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .radio {
      display: flex;
      flex-flow: column nowrap;
      row-gap:  0.37rem;
    }
  
    .label {
      font-size: 0.75rem
    }
  
    .options {
      flex-direction: column;
      gap: 0.3rem;
    }
  }