main {
    position:fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    background-image: url("../../assets/images/new bg and logos/newBg_50.webp");
    background-size: cover;
    background-position-y: 100%;
}

.container {
  background-color: var(--color-white);
  border-radius: 1.5rem;
  box-shadow: 0 0 1.9rem rgba(0, 0, 0, 0.4);
  width: 55rem;
  align-self: center;
  padding: 1.8rem;
}

.contents {
    
  }
 
  .heading {
    padding: 0 0.6rem;
    color: var(--color-gray-1);
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 1.6rem !important;
  }

.screeningHeader {
    font-weight: 600;
    text-align: center;
  }

  @media screen and (min-width: 480px) and (max-width: 1100px) {
    .container{
      width: 80%;
    }
  }


  @media screen and (max-width: 480px) {
    .container{
      width: 80%;
    }
  }

@media (min-width: 750px) {

    .container {
        border-radius: 1rem;
        padding: 3rem;
    }
}